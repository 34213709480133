import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Section from '../Section';
import { dimensions, colors, transitions, breakpoints } from '../../styles/variables';
import Parallax, { Blob } from '../Parallax';
import Button from '../Button';
import { PostListItem } from '../PostList';
import sanitizeSrcSet from '../../utils/sanitizeSrcSet';

export const query = graphql`
  query FrontpageNewsSectionQuery {
    strapi {
      frontpage {
        news_all_link {
          id
          label
          page {
            path
            title
          }
        }
        news_title
      }
      posts(limit: 3, sort: "created_at:desc") {
        ...PostListItemFragment
      }
    }
  }
`;

export interface FrontpageNewsSectionQueryProps {
  strapi: {
    frontpage: {
      news_title: string;
      news_all_link: {
        id: string;
        label: string;
        page: {
          path: string;
          title: string;
        };
      };
    };
    posts: PostListItem[];
  };
}

const columnBreakpoint = '@media (max-width: 860px)';

export const Article: React.FC<{ article: PostListItem; className?: string }> = ({
  article,
  className,
}) => (
  <Link
    to={article.path}
    css={{
      width: '100%',
      paddingRight: 50,
      textDecoration: 'none',
      transition: transitions.fast,
      '&:hover, &:focus': {
        textDecoration: 'none',
        transform: 'translateY(-3px)',
        color: colors.purple,
      },
    }}
    className={className}
  >
    {article.image && article.image.imageFile.childImageSharp ? (
      <img
        alt={article.title}
        src={article.image.imageFile.childImageSharp.fluid.src}
        srcSet={sanitizeSrcSet(article.image.imageFile.childImageSharp.fluid.srcSet)}
        sizes="400px"
        css={{
          objectFit: 'cover',
          height: 180,
          width: 310,
          borderRadius: dimensions.borderRadius,
          [columnBreakpoint]: {
            display: 'none',
          },
        }}
      />
    ) : (
      <div
        css={{
          height: 180,
          width: '100%',
          borderRadius: dimensions.borderRadius,
          backgroundColor: colors.blue,
          marginBottom: '1rem',
          opacity: 0.2,
          [columnBreakpoint]: {
            display: 'none',
          },
        }}
      />
    )}
    <div
      css={{
        paddingRight: 10,
        paddingLeft: 20,
        [columnBreakpoint]: {
          paddingLeft: 0,
          marginBottom: 10,
        },
      }}
    >
      <h3
        css={{
          fontSize: `${dimensions.fontSize.large}rem`,
          lineHeight: `${dimensions.lineHeight.regular}rem`,
          fontWeight: dimensions.fontWeight.regular,
          marginBottom: '0.5rem',
          marginTop: 8,
          textDecoration: 'underline',
        }}
      >
        {article.title}
      </h3>
      <p
        css={{
          color: colors.darkgray,
          fontSize: `${dimensions.fontSize.small}rem`,
          lineHeight: dimensions.lineHeight.compact,
        }}
      >
        {article.description}
      </p>
    </div>
  </Link>
);

const NewsSection: React.FC = () => {
  const { strapi } = useStaticQuery(query) as FrontpageNewsSectionQueryProps;
  const { frontpage, posts } = strapi;

  return (
    <div
      css={{
        color: colors.blue,
        marginBottom: 80,
        position: 'relative',
      }}
    >
      <Parallax
        css={{
          left: '2%',
          top: 200,
          '@media (max-width: 1200px)': {
            top: 'auto',
            bottom: 100,
            left: -100,
          },
        }}
        modifier={-0.05}
      >
        <Blob css={{ color: colors.yellow, width: 207, transform: 'rotate(99deg)' }} />
      </Parallax>
      <Section level={4} css={{ position: 'relative' }}>
        <h2
          css={{
            fontSize: `${dimensions.headingSizes.h1}rem`,
            fontWeight: dimensions.fontWeight.fat,
            transform: 'rotate(1deg)',
            marginBottom: 40,
            [`@media (max-width: ${breakpoints.sm}px)`]: {
              fontSize: `${dimensions.fontSize.huge}rem`,
              marginBottom: 20,
            },
          }}
        >
          {frontpage.news_title}
        </h2>
      </Section>
      <Section
        level={3}
        css={{
          display: 'flex',
          position: 'relative',
          [columnBreakpoint]: {
            flexDirection: 'column',
            alignItems: 'center',
          },
        }}
      >
        {posts.map(post => (
          <Article key={post.id} article={post} />
        ))}
      </Section>
      <Section level={4} css={{ marginTop: 30, position: 'relative' }}>
        <Button to={frontpage.news_all_link.page.path} css={{ display: 'inline-block' }}>
          {frontpage.news_all_link.label}
        </Button>
      </Section>
    </div>
  );
};

export default NewsSection;
