import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { colors, dimensions, breakpoints } from '../../styles/variables';
import Parallax, { Triangle, Ring, Blob } from '../Parallax';
import Section from '../Section';
import Button from '../Button';
import ArrowDownIcon from '../../icons/arrow-down.svg';
import { ImageFragment } from '../../fragments/Image';
import sanitizeSrcSet from '../../utils/sanitizeSrcSet';

export const query = graphql`
  query FrontpageHeroQuery {
    strapi {
      frontpage {
        top_section_text
        top_section_link {
          id
          label
          page {
            path
          }
        }
        top_section_image {
          ...ImageFragment
        }
      }
    }
  }
`;

export interface FrontpageHeroQueryProps {
  strapi: {
    frontpage: {
      top_section_text: string;
      top_section_link: {
        id: string;
        label: string;
        page: {
          path: string;
        };
      };
      top_section_image?: ImageFragment;
    };
  };
}

const ParallaxTriangle: React.FC<{ opacity: number; rotate: number }> = ({ opacity, rotate }) => (
  <Parallax
    css={{
      left: '-18%',
      top: '50%',
      [`@media (max-width: ${breakpoints.lg}px)`]: {
        left: -200,
      },
    }}
    modifier={0.1}
  >
    <Triangle
      css={{
        color: colors.blue,
        opacity,
        width: 1046,
        marginTop: -650,
        transform: `rotate(${rotate}deg)`,
        [`@media (max-width: ${breakpoints.sm}px)`]: {
          width: 592,
          marginTop: -320,
          marginLeft: 40,
        },
      }}
    />
  </Parallax>
);

const Hero: React.FC = () => {
  const { strapi } = useStaticQuery(query) as FrontpageHeroQueryProps;
  const { frontpage } = strapi;

  return (
    <>
      <Section
        level={2}
        css={{
          height: 'calc(100vh - 220px)',
          maxHeight: 700,
          position: 'relative',
          zIndex: 2,
        }}
      >
        <ParallaxTriangle opacity={0.05} rotate={-14} />
        <div
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            overflow: 'hidden',
            borderRadius: dimensions.borderRadius,
            backgroundColor: colors.blue,
            transform: 'rotate(0.5deg)',
          }}
        >
          <Parallax modifier={0.05} css={{ width: '100%', height: '100%' }}>
            {frontpage.top_section_image && frontpage.top_section_image.imageFile.childImageSharp && (
              <img
                alt={frontpage.top_section_text}
                src={frontpage.top_section_image.imageFile.childImageSharp.fluid.src}
                srcSet={sanitizeSrcSet(
                  frontpage.top_section_image.imageFile.childImageSharp.fluid.srcSet,
                )}
                sizes="80vw"
                css={{
                  width: '110%',
                  height: '110%',
                  marginTop: '-4%',
                  objectFit: 'cover',
                }}
              />
            )}
          </Parallax>
          <ParallaxTriangle opacity={0.7} rotate={-15} />
          <Parallax
            css={{
              right: 140,
              top: 0,
              [`@media (max-width: ${breakpoints.md}px)`]: {
                top: -40,
              },
            }}
            modifier={-0.2}
          >
            <Blob
              css={{
                color: colors.yellow,
                opacity: 0.8,
                width: 115,
              }}
            />
          </Parallax>
          <Section
            level={4}
            css={{
              position: 'absolute',
              top: 50,
              bottom: 50,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              color: colors.white,
              [`@media (max-width: ${breakpoints.md}px)`]: {
                alignItems: 'center',
                justifyContent: 'flex-end',
              },
            }}
            pad
          >
            <h1
              css={{
                fontSize: '3.75rem',
                maxWidth: 500,
                marginBottom: 30,
                [`@media (max-width: ${breakpoints.md}px)`]: {
                  fontSize: '2.5rem',
                  textAlign: 'center',
                },
              }}
            >
              {frontpage.top_section_text}
            </h1>
            <Button to={frontpage.top_section_link.page.path}>
              {frontpage.top_section_link.label}
            </Button>
          </Section>
        </div>
        <Parallax
          css={{
            right: '45%',
            bottom: 200,
            [`@media (max-width: ${breakpoints.md}px)`]: {
              right: 140,
              bottom: 70,
            },
          }}
          modifier={-0.1}
        >
          <Ring
            css={{
              color: colors.orange,
              width: 294,
              transform: 'rotate(81deg)',
              path: { strokeWidth: 1.1 },
              [`@media (max-width: ${breakpoints.md}px)`]: {
                width: 111,
                path: { strokeWidth: 4 },
              },
            }}
          />
        </Parallax>
      </Section>
      <Parallax
        modifier={-0.2}
        css={{
          textAlign: 'center',
          width: '100%',
          marginTop: -30,
          zIndex: 1,
        }}
      >
        <ArrowDownIcon css={{ color: colors.blue, display: 'inline-block' }} />
      </Parallax>
    </>
  );
};

export default Hero;
