import React from 'react';
import Hero from '../components/Frontpage/Hero';
import LinksSection from '../components/Frontpage/LinksSection';
import NewsSection from '../components/Frontpage/NewsSection';

const IndexPage = () => {
  return (
    <>
      <Hero />
      <LinksSection />
      <NewsSection />
    </>
  );
};

export default IndexPage;
