import React from 'react';
import { Link } from 'gatsby';
import { transparentize } from 'polished';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import useWindowSize from '@rooks/use-window-size';
import Parallax, { Blob } from '../Parallax';
import { colors, dimensions, transitions, breakpoints, fonts } from '../../styles/variables';
import Section from '../Section';
import ArrowDownIcon from '../../icons/arrow-down.svg';
import { LinkInternalWithDescriptionFragment } from '../../fragments/LinkInternalWithDescription';
import sanitizeSrcSet from '../../utils/sanitizeSrcSet';

type CarouselProps = {
  links: LinkInternalWithDescriptionFragment[];
  title: string;
};

const Carousel: React.FC<CarouselProps> = ({ links, title }) => {
  const innerWidth = useWindowSize().innerWidth || 0;

  const paddingLeft = innerWidth > 1300 ? 100 : 20;
  const itemPadding = 30;
  const itemWidth = innerWidth > 940 ? 480 : 300;
  const itemsPerSlide = (innerWidth - paddingLeft) / (itemWidth + itemPadding);

  return (
    <CarouselProvider
      visibleSlides={itemsPerSlide}
      totalSlides={links.length}
      naturalSlideWidth={480}
      naturalSlideHeight={360}
      isIntrinsicHeight
    >
      <Section
        level={4}
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 48,
          [`@media (max-width: ${breakpoints.sm}px)`]: {
            marginBottom: 24,
          },
        }}
      >
        <h2
          css={{
            fontSize: `${dimensions.headingSizes.h1}rem`,
            fontWeight: dimensions.fontWeight.fat,
            transform: 'rotate(-1.5deg)',
            marginTop: 0,
            marginBottom: 0,
            [`@media (max-width: ${breakpoints.sm}px)`]: {
              fontSize: `${dimensions.fontSize.huge}rem`,
            },
          }}
        >
          {title}
        </h2>
        <div
          css={{
            position: 'relative',
            [`@media (max-width: ${breakpoints.sm}px)`]: { display: 'none' },
          }}
        >
          <Parallax css={{ left: -67, top: -105 }} modifier={-0.1}>
            <Blob css={{ color: colors.blue, width: 270, transform: 'rotate(99deg)' }} />
          </Parallax>
          {['left', 'right'].map(d => {
            const Comp = d === 'left' ? ButtonBack : ButtonNext;

            return (
              <Comp
                key={d}
                css={{
                  position: 'relative',
                  backgroundColor: colors.turquoise,
                  borderRadius: '100%',
                  width: 36,
                  height: 36,
                  border: 'none',
                  marginRight: d === 'left' ? 10 : 0,
                  transition: transitions.fast,
                  ':disabled': {
                    opacity: 0.2,
                    cursor: 'auto',
                  },
                }}
              >
                <ArrowDownIcon
                  css={{
                    color: colors.white,
                    display: 'inline-block',
                    transform:
                      d === 'left'
                        ? 'translate3d(-1px, 1px, 0) rotate(90deg)'
                        : 'translate3d(1px, 1px, 0) rotate(-90deg)',
                  }}
                />
              </Comp>
            );
          })}
        </div>
      </Section>
      <Slider
        css={{
          paddingLeft: 100,
          '@media (max-width: 1300px)': {
            paddingLeft: 20,
          },
        }}
      >
        {links.map((link, index) => {
          const label = link.label || link.page.title;

          return (
            <Slide index={index} key={link.id}>
              <Link
                to={link.page.path}
                css={{
                  textDecoration: 'none',
                  '&:hover, &:focus': {
                    textDecoration: 'none',
                    '& > :first-of-type': {
                      transform: 'translateY(3px)',
                    },
                    '& > :last-child': {
                      transform: 'translateY(-3px)',
                    },
                  },
                }}
              >
                <div
                  css={{
                    position: 'relative',
                    width: 480,
                    height: 250,
                    borderRadius: dimensions.borderRadius,
                    overflow: 'hidden',
                    transition: transitions.fast,
                    '@media (max-width: 940px)': {
                      width: 300,
                      height: 156,
                    },
                  }}
                >
                  {link.page.image && link.page.image.imageFile.childImageSharp && (
                    <img
                      alt={label}
                      src={link.page.image.imageFile.childImageSharp.fluid.src}
                      srcSet={sanitizeSrcSet(
                        link.page.image.imageFile.childImageSharp.fluid.srcSet,
                      )}
                      sizes="400px"
                      css={{ objectFit: 'cover', height: '100%', width: '100%' }}
                    />
                  )}
                  <div
                    css={{
                      position: 'absolute',
                      right: 0,
                      left: 0,
                      bottom: 0,
                      height: 40,
                      background: `linear-gradient(180deg, ${transparentize(
                        1,
                        colors.blue,
                      )} 0%, ${transparentize(0.7, colors.blue)}  100%)`,
                      pointerEvents: 'none',
                    }}
                  />
                </div>
                <div
                  css={{
                    position: 'relative',
                    paddingLeft: 40,
                    paddingRight: 80,
                    marginTop: -38,
                    transition: transitions.fast,
                    '@media (max-width: 940px)': {
                      width: 280,
                      paddingLeft: 20,
                      paddingRight: 20,
                    },
                  }}
                >
                  <h3
                    css={{
                      fontStyle: 'italic',
                      fontFamily: fonts.sansSerif,
                      fontWeight: dimensions.fontWeight.regular,
                      marginBottom: '1rem',
                    }}
                  >
                    {label}
                  </h3>
                  <p>{link.description || link.page.description}</p>
                </div>
              </Link>
            </Slide>
          );
        })}
      </Slider>
    </CarouselProvider>
  );
};

export default Carousel;
