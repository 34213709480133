import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { transparentize } from 'polished';
import { colors, dimensions, transitions } from '../../styles/variables';
import { LinkInternalWithDescriptionFragment } from '../../fragments/LinkInternalWithDescription';
import { LinkInternalFragment } from '../../fragments/LinkInternal';
import Section from '../Section';
import Parallax, { Ring, Blob, TriangleStroke, Triangle } from '../Parallax';
import Button from '../Button';
import Carousel from './Carousel';
import sanitizeSrcSet from '../../utils/sanitizeSrcSet';

export const query = graphql`
  query FrontpageLinksSectionQuery {
    strapi {
      frontpage {
        links {
          ...LinkInternalWithDescriptionFragment
        }
        tilbud_links {
          ...LinkInternalWithDescriptionFragment
        }
        tilbud_all_link {
          ...LinkInternalFragment
        }
        tilbud_title
      }
    }
  }
`;

export interface FrontpageLinksSectionQueryProps {
  strapi: {
    frontpage: {
      links: LinkInternalWithDescriptionFragment[];
      tilbud_title: string;
      tilbud_links: LinkInternalWithDescriptionFragment[];
      tilbud_all_link: LinkInternalFragment;
    };
  };
}

const columnBreakpoint = '@media (max-width: 860px)';

const LinkBox: React.FC<{ link: LinkInternalWithDescriptionFragment }> = ({ link }) => {
  const label = link.label || link.page.title;

  return (
    <Link
      to={link.page.path}
      css={{
        width: '100%',
        textDecoration: 'none',
        '&:hover, &:focus': {
          textDecoration: 'none',
          '& > :first-of-type': {
            transform: 'translateY(3px) rotate(-1.5deg)',
          },
          '& > :last-of-type': {
            transform: 'translateY(-3px)',
          },
        },
        [columnBreakpoint]: {
          marginBottom: 50,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      }}
    >
      <div
        css={{
          position: 'relative',
          width: 240,
          height: 200,
          borderRadius: dimensions.borderRadius,
          overflow: 'hidden',
          transition: transitions.fast,
          transform: 'rotate(-1.5deg)',
          [columnBreakpoint]: {
            height: 170,
            width: '70%',
            minWidth: 240,
          },
        }}
      >
        {link.page.image && link.page.image.imageFile.childImageSharp && (
          <img
            alt={label}
            src={link.page.image.imageFile.childImageSharp.fluid.src}
            srcSet={sanitizeSrcSet(link.page.image.imageFile.childImageSharp.fluid.srcSet)}
            sizes="400px"
            css={{ objectFit: 'cover', height: '100%', width: '100%' }}
          />
        )}
        <div
          css={{
            position: 'absolute',
            right: 0,
            left: 0,
            bottom: 0,
            height: 40,
            background: `linear-gradient(180deg, ${transparentize(
              1,
              colors.blue,
            )} 0%, ${transparentize(0.7, colors.blue)}  100%)`,
            pointerEvents: 'none',
          }}
        />
      </div>
      <div
        css={{
          position: 'relative',
          paddingLeft: 80,
          paddingRight: 20,
          marginTop: -60,
          transition: transitions.fast,
          [columnBreakpoint]: {
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: -35,
          },
        }}
      >
        <h3
          css={{
            width: 0,
            fontStyle: 'italic',
            fontWeight: dimensions.fontWeight.regular,
            marginBottom: '1rem',
            [columnBreakpoint]: {
              width: 'auto',
              textAlign: 'center',
            },
          }}
        >
          {label}
        </h3>
        <p
          css={{
            [columnBreakpoint]: {
              textAlign: 'center',
            },
          }}
        >
          {link.description || link.page.description}
        </p>
      </div>
    </Link>
  );
};

const LinksSection: React.FC = () => {
  const { strapi } = useStaticQuery(query) as FrontpageLinksSectionQueryProps;
  const { frontpage } = strapi;

  return (
    <div
      css={{
        position: 'relative',
        marginTop: 160,
        marginBottom: 40,
        paddingTop: 130,
        paddingBottom: 180,
        color: colors.white,
        [columnBreakpoint]: {
          marginTop: 80,
          paddingTop: 80,
          paddingBottom: 150,
          marginBottom: 0,
        },
      }}
    >
      <Parallax
        modifier={-0.05}
        css={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          width: 'auto',
          height: 'auto',
        }}
      >
        <div
          css={{
            width: '110%',
            marginLeft: '-5%',
            height: '100%',
            backgroundColor: colors.darkPurple,
            transform: 'rotate(-0.5deg)',
          }}
        />
      </Parallax>
      <Parallax
        css={{
          left: '45%',
          top: -15,
          [columnBreakpoint]: {
            display: 'none',
          },
        }}
        modifier={0.025}
      >
        <Ring
          css={{
            color: colors.turquoise,
            width: 26,
            transform: 'rotate(-121deg)',
            path: { strokeWidth: 5 },
          }}
        />
      </Parallax>
      <div css={{ position: 'relative' }}>
        <Parallax
          css={{
            right: '40%',
            top: -40,
            [columnBreakpoint]: {
              right: 40,
              top: 300,
            },
          }}
          modifier={-0.05}
        >
          <Blob css={{ color: colors.purple, width: 84 }} />
        </Parallax>
        <Parallax
          css={{
            left: '30%',
            top: 40,
            [columnBreakpoint]: {
              left: '-10%',
              top: -100,
            },
          }}
          modifier={-0.05}
        >
          <TriangleStroke
            css={{
              color: colors.yellow,
              width: 233,
              transform: 'rotate(55deg)',
              path: { strokeWidth: 0.5 },
            }}
          />
        </Parallax>
        <Parallax
          css={{
            left: '5%',
            top: 700,
            display: 'none',
            [columnBreakpoint]: {
              display: 'block',
            },
          }}
          modifier={0.075}
        >
          <Triangle
            css={{
              color: colors.orange,
              width: 80,
              transform: 'rotate(-57deg)',
            }}
          />
        </Parallax>
        <Section
          level={3}
          css={{
            position: 'relative',
            display: 'flex',
            marginBottom: 140,
            [columnBreakpoint]: {
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 50,
            },
          }}
        >
          <LinkBox link={frontpage.links[0]} />
          <LinkBox link={frontpage.links[1]} />
          <LinkBox link={frontpage.links[2]} />
        </Section>
        <Parallax css={{ left: '16%', top: -20 }} modifier={0.05}>
          <TriangleStroke
            css={{
              color: colors.blue,
              width: 56,
              transform: 'rotate(-133deg)',
              [columnBreakpoint]: {
                display: 'none',
              },
            }}
          />
        </Parallax>
        <Parallax
          css={{
            right: '20%',
            top: 60,
            display: 'none',
            [columnBreakpoint]: {
              display: 'block',
            },
          }}
          modifier={0.025}
        >
          <Ring
            css={{
              color: colors.turquoise,
              width: 26,
              transform: 'rotate(-121deg)',
              path: { strokeWidth: 6 },
            }}
          />
        </Parallax>
        <div css={{ position: 'relative' }}>
          <Parallax
            css={{ left: -40, top: -200, [columnBreakpoint]: { display: 'none' } }}
            modifier={0.025}
          >
            <Blob css={{ color: colors.turquoise, width: 154 }} />
          </Parallax>
        </div>
        <Carousel title={frontpage.tilbud_title} links={frontpage.tilbud_links} />
        <Section level={4} css={{ marginTop: 50 }}>
          <Button to={frontpage.tilbud_all_link.page.path} css={{ display: 'inline-block' }}>
            {frontpage.tilbud_all_link.label || frontpage.tilbud_all_link.page.title}
          </Button>
        </Section>
        <div css={{ position: 'relative' }}>
          <Parallax css={{ left: '40%', top: 70 }} modifier={0.025}>
            <Ring css={{ color: colors.orange, width: 80, path: { strokeWidth: 4 } }} />
          </Parallax>
        </div>
      </div>
    </div>
  );
};

export default LinksSection;
