const sanitizeSrcSet = (srcSet: string): string =>
  srcSet
    .split(',\n')
    .filter(str => {
      const match = str.match(/\s([0-9]+)w/);
      return match && Number(match[1]) < 2000;
    })
    .join(',\n');

export default sanitizeSrcSet;
